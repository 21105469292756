














































































import BaseButtonText from '@/components/base/button/BaseButtonText.vue';
import BaseGridTable from '@/components/base/grid/BaseGridTable.vue';
import BaseSelect from '@/components/base/BaseSelect.vue';
import LayoutPage from '@/layouts/LayoutPage.vue';
import Vue, { VueConstructor } from 'vue';
import { cloneDeep as _cloneDeep } from 'lodash';
import { MCategory } from '@/models/MCategory';
import { MCustomer } from '@/models/MCustomer';
import { MEarning } from '@/models/MEarning';
import { MInvoice } from '@/models/MInvoice';
import { mixinAlert } from '@/components/mixins/alert';
import { mixinDate } from '@/components/mixins/date';
import { mixinModal } from '@/components/mixins/modal';
import { mixinPDF } from '@/components/mixins/pdf';
import { MOutgoing } from '@/models/MOutgoing';
import { MProduct } from '@/models/MProduct';
import { MSales } from '@/models/MSales';
import { MTax } from '@/models/MTax';

export default (Vue as VueConstructor<Vue>).extend({
  name: 'IncomeShow',

  components: {
    BaseButtonText,
    BaseGridTable,
    BaseSelect,
    LayoutPage,
  },

  mixins: [
    mixinAlert,
    mixinDate,
    mixinModal,
    mixinPDF,
  ],

  data() {
    return {
      currentYear: String(new Date().getFullYear()),

      gridActionList: [],

      gridColumnDefinition: [
        {
          headerName: String(this.$t('success.property.accountName')),
          field: 'accountName',
        },
        {
          headerName: String(this.$t('success.property.accountingNumber')),
          field: 'code',
        },
        {
          headerName: String(this.$tc('success.property.income', 2)),
          field: 'income',
        },
        {
          headerName: String(this.$tc('success.property.outcome', 2)),
          field: 'outcome',
        },
      ],

      income: 0,

      currentMonth: 'all',

      monthList: [
        {
          month: 'all',
        },
        {
          month: '01',
        },
        {
          month: '02',
        },
        {
          month: '03',
        },
        {
          month: '04',
        },
        {
          month: '05',
        },
        {
          month: '06',
        },
        {
          month: '07',
        },
        {
          month: '08',
        },
        {
          month: '09',
        },
        {
          month: '10',
        },
        {
          month: '11',
        },
        {
          month: '12',
        },
      ],

      outcome: 0,

      yearList: [
        {
          year: 2020,
        },

        {
          year: 2021,
        },
        {
          year: 2022,
        },
        {
          year: 2023,
        },
        {
          year: 2024,
        },
        {
          year: 2025,
        },
        {
          year: 2026,
        },
        {
          year: 2027,
        },
        {
          year: 2028,
        },
        {
          year: 2029,
        },
        {
          year: 2030,
        },
      ],
    };
  },

  computed: {
    displayedYear(): Object {
      return {
        year: this.currentYear,
      };
    },

    displayedMonth(): Object {
      return {
        month: this.currentMonth,
      };
    },

    invoiceList(): Array<MInvoice> {
      if (this.displayedMonth.month !== 'all') {
        return MInvoice
          .query()
          .withAllRecursive()
          .where(invoice => {
            return invoice.DatePaid !== '' &&
                  invoice.DatePaid !== null &&
                  invoice?.DatePaid?.substr(0, 4) === this.displayedYear.year &&
                  invoice?.DatePaid?.substr(5, 2) === this.displayedMonth.month;
          }).get();
      }

      return MInvoice
        .query()
        .withAllRecursive()
        .where(invoice => {
          return invoice.DatePaid !== '' &&
                invoice.DatePaid !== null &&
                invoice.DatePaid.substr(0, 4) === this.displayedYear.year;
        }).get();
    },

    incomeList(): Array<any> {
      const incomeList = [];

      this.invoiceList.forEach(invoice => {
        invoice.Sales.forEach(sale => {
          const saleData = sale.asListData;

          const incomeExists = incomeList.find(income => {
            return (
              income.code === saleData.accountCode
            );
          });

          if (incomeExists !== undefined) {
            incomeExists.income += Number(saleData.totalPrice);
          } else {
            incomeList.push({
              accountName: saleData.accountName,
              code: saleData.accountCode,
              income: Number(saleData.totalPrice),
              outcome: '',
              actions: saleData.actions,
            });
          }

          this.income += Number(saleData.totalPrice);
        });
      });

      return incomeList;
    },

    gridRowData(): Array<any> {
      const incomeList = _cloneDeep(this.incomeList);

      return incomeList.map(income => {
        income.income = MSales.formatedPrice(income.income);

        return income;
      });
    },

    gridRowDataOutcome(): Array<any> {
      const outcomeList = _cloneDeep(this.outcomeList);

      return outcomeList.map(outcome => {
        outcome.outcome = MSales.formatedPrice(outcome.outcome);

        return outcome;
      });
    },

    outcomeList(): Array<any> {
      const outcomeList = [];

      const outgoingList = MOutgoing
        .query()
        .where((outgoing) => {
          if (this.currentMonth !== 'all') {
            return outgoing.Date.substr(0, 4) === this.currentYear && outgoing.Date.substr(5, 2) === this.currentMonth;
          }

          return outgoing.Date.substr(0, 4) === this.currentYear;
        }).get();

      outgoingList.forEach(outgoing => {
        const outgoingData = outgoing.asJson;

        const outgoingExists = outcomeList.find(outcome => {
          return (
            outcome.code === outgoingData.account
          );
        });

        if (outgoingExists !== undefined) {
          outgoingExists.outcome += Number(outgoing.GrossAmount);
        } else {
          outcomeList.push({
            accountName: outgoingData.accountNumberName,
            code: outgoingData.account,
            income: '',
            outcome: Number(outgoing.GrossAmount),
            actions: outgoing.Id,
          });
        }
      });

      outcomeList.forEach(outcome => {
        this.outcome += Number(outcome.outcome);
      });

      return outcomeList;
    },

    total(): string {
      return MSales.formatedPrice((this.income - this.outcome));
    },

    totalIncome(): string {
      return MSales.formatedPrice(this.income);
    },

    totalOutcome(): string {
      return MSales.formatedPrice(this.outcome);
    },
  },

  methods: {
    exportPdf(): void {
      let title = `${this.$tc('success.model', 2)}-${this.currentYear}`;

      if (this.currentMonth !== 'all') {
        title = `${this.$tc('success.model', 2)}-${this.formatMonth(this.currentMonth)}-${this.currentYear}`;
      }

      this.exportSuccessData(this.gridRowData, this.gridRowDataOutcome, this.gridColumnDefinition, title);
    },

    async loadYear(year: any): Promise<any> {
      this.income = 0;

      this.outcome = 0;

      this.currentYear = year.year;

      await MEarning._fetch(year.year);
    },

    async loadMonth(month: any): Promise<any> {
      this.income = 0;

      this.outcome = 0;

      this.currentMonth = month.month;
    },
  },
});
